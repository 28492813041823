import { ref } from 'vue'
import useLanguage from './useLanguage'
import useAbt from './useAbt'
import { shoppingBagIndex, shoppingBagDelete, shoppingBagModifyCheckStatus, shoppingBagUpdate } from '../utils/fetcher'
import { initOverLimitState, showOverLimitToast } from '../utils/overLimit'
import { UPDATE_CART_INFO_ACTIONS } from '../constants'

const getCommonParams = (option, abt) => {
  // cart_best_coupon_price 为 on 且券凑单时，自动用券、自动用积分不取业务传入
  const isCartBestCouponPrice = abt.value?.cartPriceStyle?.param?.cart_best_coupon_price == 'on'
  let autoUseCoupon
  let autoUsePoint
  if (isCartBestCouponPrice && option.value?.type == 'coupon') {
    const autoUseCouponByAbt = abt.value?.Cartshowcoupon?.param?.cart_show_coupon_switch == 'on'
    const autoUsePointByAbt = (abt.value?.cartautopoints?.param?.is_auto_point || '').split('_')[0]
    autoUseCoupon = autoUseCouponByAbt ? '1' : '0'
    autoUsePoint = autoUsePointByAbt || '0'
  } else {
    autoUseCoupon = option.value.autoUseCoupon || '0'
    autoUsePoint = option.value.autoUsePoint || '0'
  }
  return {
    cartParams: {
      ...option.value.address,
      userLocalSizeCountry: option.value.userLocalSizeCountry || '',
      autoUseCoupon,
      autoUsePoint,
    },
    sceneData: {
      scene: option.value.scene || '',
      data: {
        ...(option.value.scene == 'promotion'
          ? { promotionIds: [option.value.promotionId] || [] }
          : { couponCodes: [option.value.promotionId] || [] }),
      },
    },
  }
}

export default function useBusinessCart(option) {
  const description = ref({})
  const metaInfo = ref({})
  const groups = ref([])
  const pageLoading = ref(true)

  const { language, getLanguageData } = useLanguage()
  const { ABTParams, getAbtData, hasFetchedAbt } = useAbt()

  const loading = ref(false)
  const showLoading = () => loading.value = true
  const hideLoading = () => loading.value = false

  const isOpenDelete = ref(false)
  const deleteItemData = ref(null)
  const openDelete = (item) => {
    deleteItemData.value = item
    isOpenDelete.value = true
  }
  const closeDelete = () => {
    isOpenDelete.value = false
    deleteItemData.value = null
  }

  const updateCartInfo = ({ data, action }) => {
    description.value = data?.description || {}
    metaInfo.value = data?.metaInfo || {}
    groups.value = data?.cartInfo?.groups || []
    option.value.cartUpdateCallBack &&
      option.value.cartUpdateCallBack({
        metaInfo: metaInfo.value,
        groups: groups.value,
        supportItemInfo: data?.supportItemInfo,
        action,
      })
  }

  const getData = async () => {
    pageLoading.value = true
    try {
      if (!option.value.promotionId) return
      await init()
      const commonParams = getCommonParams(option, ABTParams)
      const data = { ...commonParams }
      const res = await shoppingBagIndex(data)
      if (res.code == 0) {
        initOverLimitState(res)
        updateCartInfo({ data: res.info, action: UPDATE_CART_INFO_ACTIONS.FETCH })
      }
      pageLoading.value = false
    } catch {
      // empty
    }
  }


  const modify = async ({ cartIdList, operationType, appendIdList = [] }) => {
    showLoading()
    try {
      await init()
      const commonParams = getCommonParams(option, ABTParams)
      const data = {
        ...commonParams,
        cartUpdateParams: {
          cartIdList,
          operationType,
          appendIdList,
        },
      }
      const res = await shoppingBagModifyCheckStatus(data)
      if (res.code == 0) {
        // 批量编辑只做更新不展示
        showOverLimitToast(cartIdList.length > 1 ? -1 : cartIdList[0], res)
        updateCartInfo({ data: res.info, action: UPDATE_CART_INFO_ACTIONS.MODIFY })
      }
    } finally {
      hideLoading()
    }
  }

  const updateQty = async ({  cartId, quantity, isChecked, appendIdList = [], onSuccess, onError }) => {
    showLoading()
    try {
      await init()
      const commonParams = getCommonParams(option, ABTParams)
      const data = {
        ...commonParams,
        cartUpdateParams: {
          cartId,
          quantity,
          isChecked,
          appendIdList,
        },
      }
      const res = await shoppingBagUpdate(data)
      if (res.code == 0) {
        showOverLimitToast(cartId, res)
        updateCartInfo({ data: res.info, action: UPDATE_CART_INFO_ACTIONS.UPDATE_QUANTITY })
        onSuccess && onSuccess()
      } else {
        onError && onError(res)
      }
    } catch {
      onError && onError()
    } finally {
      hideLoading()
    }
  }

  const del = async ({ cartIdList }) => {
    showLoading()
    try {
      await init()
      const commonParams = getCommonParams(option, ABTParams)
      const data = {
        ...commonParams,
        cartUpdateParams: {
          cartIdList,
        },
      }
      const res = await shoppingBagDelete(data)
      if (res.code == 0) {
        showOverLimitToast(cartIdList[0], res)
        updateCartInfo({ data: res.info, action: UPDATE_CART_INFO_ACTIONS.DELETE })
      }
    } finally {
      hideLoading()
    }
  }

  const refetchData = async ({ action = '', isShowLoading = false } = {}) => {
    isShowLoading && showLoading()
    try {
      await init()
      const commonParams = getCommonParams(option, ABTParams)
      const data = { ...commonParams }
      const res = await shoppingBagIndex(data)
      if (res.code == 0) {
        updateCartInfo({ data: res.info, action })
      }
    } finally {
      isShowLoading && hideLoading()
    }
  }

  const init = async () => {
    if (!hasFetchedAbt.value) {
      await Promise.all([getLanguageData(), getAbtData()])
    }
  }

  init()
  

  return {
    // variables
    language,
    groups,
    description,
    metaInfo,
    pageLoading,
    loading,
    isOpenDelete,
    deleteItemData,
    ABTParams,

    // methods
    getData,
    updateQty,
    modify,
    del,
    refetchData,
    openDelete,
    closeDelete,
  }
}
