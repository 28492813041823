/**
 * 与接口相关的工具函数
 */
import schttp from 'public/src/services/schttp'

export const getLanguage = (data) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/system/configs/multi_language',
    data,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
    useBffApi: true,
  })
}

export const getAbt = (params) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'GET',
    url: '/abt/merge/get_abt_by_poskey',
    params,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
    useBffApi: true,
  })
}

let shoppingBagIndexAbort
export const shoppingBagIndex = (data) => {
  shoppingBagIndexAbort?.abort()
  shoppingBagIndexAbort = new SchttpAbortCon()
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/cart/shopping_bag/index',
    data,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
    useBffApi: true,
    signal: shoppingBagIndexAbort?.signal,
  })
}
export const shoppingBagDelete = (data) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/cart/shopping_bag/delete',
    data,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
    useBffApi: true,
  })
}
export const shoppingBagModifyCheckStatus = (data) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/cart/shopping_bag/modify_check_status',
    data,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
    useBffApi: true,
  })
}
export const shoppingBagUpdateAttr = (data) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/cart/shopping_bag/update_attr',
    data,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
    useBffApi: true,
  })
}
export const shoppingBagUpdate = (data) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/cart/shopping_bag/update',
    data,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
    useBffApi: true,
  })
}
