<template>
  <div class="preview-goods">
    <div 
      v-for="(item, i) in showList"
      :key="i"
      class="goods"
    >
      <img :src="item.goodsImgs" />
      <div 
        v-if="i == 2 && modalText"
        class="modal"
      >
        <span v-if="modalText">{{ modalText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewGoods',
  props: {
    supportItemInfo: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {}
  },
  computed: {
    showList() {
      return this.supportItemInfo?.goodInfos || []
    },
    modalText() {
      const length = this.supportItemInfo?.num
      if (length >= 4 && length < 100) return `+${length - 2}`
      if (length > 100) return '99+'
      return ''
    },
  }
}
</script>

<style lang="less" scoped>
.preview-goods {
  display: flex;
  .goods {
    position: relative;
    width: 52px;
    height: 52px;
    margin-right: 8px;
    img {
      width: 100%;
      height: 100%;
    }
    .modal {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.70);
      font-size: 12px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
