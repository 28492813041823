<script setup>
const emit = defineEmits(['close'])
const handleClick = () => {
  emit('close')
}
</script>

<template>
  <Transition
    name="sui-animation-modal__drawer"
    appear
  >
    <div
      class="bc-mask"
      @click="handleClick"
    ></div>
  </Transition>
</template>

<style lang="less" scoped>
.bc-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}
</style>
