import { SMessage } from '@shein-aidc/sui-message'

const flashOverLimitId = new Set()
const stockOverLimitId = new Set()

const isFlashOverLimit = (data) => ['1', '2'].includes(data?.aggregateProductBusiness?.singleOrTotalOver) && !!data?.aggregateProductBusiness?.overLimitOriginPriceBuyTip
const isStockOverLimit = (data) => data?.aggregateProductBusiness?.stock_over == 1 && !!data?.aggregateProductBusiness?.overLimitOriginPriceBuyTip

export const initOverLimitState = (cartRes) => {
  const data = cartRes?.info?.cartInfo?.groups?.flatMap((item) => item.content) || []
  data.forEach((item) => {
    if (isFlashOverLimit(item)) {
      flashOverLimitId.add(item.id)
    }
    if (isStockOverLimit(item)) {
      stockOverLimitId.add(item.id)
    }
  })
}

export const showOverLimitToast = (id, cartRes) => {
  const data = cartRes?.info?.cartInfo?.groups?.flatMap((item) => item.content) || []
  const editItem = data.find((item) => item.id === id)

  if (!editItem) {
    flashOverLimitId.delete(id)
    stockOverLimitId.delete(id)
    return
  }

  const isShowFlashLimitToast = isFlashOverLimit(editItem) && !flashOverLimitId.has(editItem.id)
  const isShowStockLimitToast = isStockOverLimit(editItem) && !stockOverLimitId.has(editItem.id)
  const isRestFlashLimitToast = !isFlashOverLimit(editItem) && flashOverLimitId.has(editItem.id)
  const isResetStockLimitToast = !isStockOverLimit(editItem) && stockOverLimitId.has(editItem.id)

  if (isShowFlashLimitToast || isShowStockLimitToast) {
    SMessage({
      message: editItem?.aggregateProductBusiness?.overLimitOriginPriceBuyTip,
    })
  }

  if (isRestFlashLimitToast) {
    flashOverLimitId.delete(editItem.id)
  }
  if (isResetStockLimitToast) {
    stockOverLimitId.delete(editItem.id)
  }

  initOverLimitState(cartRes)
}
