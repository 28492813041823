import { daEventCenter } from 'public/src/services/eventCenter/index'

export const selectGoodsReport = ({ is_select, select_type }) => {
  daEventCenter.triggerNotice({
    daId: '1-7-10-1',
    extraData: {
      select_type,
      is_select,
      position: 'page',
    }
  })
}

export const closeBCReport = () => {
  daEventCenter.triggerNotice({
    daId: '1-7-10-2',
    extraData: {}
  })
}

export const scrollTopReport = () => {
  daEventCenter.triggerNotice({
    daId: '1-7-10-3',
    extraData: {}
  })
}

export const scrollTopExposeReport = () => {
  return {
    id: '1-7-10-4'
  }
}

export const goBackToAddReport = () => {
  daEventCenter.triggerNotice({
    daId: '1-7-10-5',
    extraData: {}
  })
}

export const goBackToAddExposeReport = () => {
  return {
    id: '1-7-10-6',
  } 
}

export const updateGoodsAttrReport = ({ sku_id }) => {
  daEventCenter.triggerNotice({
    daId: '1-7-10-7',
    extraData: {
      sku_id,
    }
  })
}

export const goodsExposeReport = ({ item, index, ABTParams }) => {
  const isShowNoCouponUse = () => {
    let flag = 'unused_coupon_tip_0'
    if (item?.store_type != 2) return flag
    if (item?.product?.have_tag == 1) {
      flag = `unused_coupon_tip_${ABTParams['Cartnoteligibleforcoupons']?.param == 'on' ? 1 : 2}`
    }
    return flag
  }
  const getOtherMark = () => {
    let result = []
    const showEvoluShein = !!item.aggregateProductBusiness?.productTags?.find(item => item.type === 'evoluShein')
    if (showEvoluShein) result.push('show_evolushein')
    if (item.aggregateProductBusiness?.productRowBiData?.amountDiscounted) {
      result.push(item.aggregateProductBusiness?.productRowBiData?.amountDiscounted)
    }
    return result.join('|')
  }
  const goodsId = item.product.goods_id
  const skuId = item.product.goods_sn
  const spuId = item.product.productRelationID
  // const index = index
  const price = item.aggregateProductBusiness?.priceData?.unitPrice?.price?.usdAmount || ''
  const originPrice = item.aggregateProductBusiness?.priceData?.originalPrice?.price?.usdAmount || ''
  const mallCode = item.mall_code
  const page = 1
  const operation = 1
  const recMark = ''
  const traceId = ''
  const attrStock = item.attr ? item.attr.stock : ''
  const insurable = item.product.insurable
  const unuseCouponTips = isShowNoCouponUse()
  const tspId = ''
  const behaviorText = ''
  const goodsNameBeforeLabelId = ''
  const sheinClubGiftTag = item.aggregateProductBusiness?.productType == 'payMemberGift'
  const dataOtherMark = getOtherMark()

  const otherMark = []
  if (behaviorText) otherMark.push(behaviorText)
  if (goodsNameBeforeLabelId) otherMark.push(`atmosphere_group_${goodsNameBeforeLabelId}`)
  if (sheinClubGiftTag) otherMark.push('show_membergift_tag')
  if (dataOtherMark) otherMark.push(dataOtherMark)

  const goods_list = `${goodsId}\`${skuId}\`${spuId}\`${+index + 1}\`${page}\`${operation}\`${recMark}\`\`pri_${price}|pri_${originPrice}\`${otherMark.join('|')}\`left_${attrStock}${insurable == 1 ? '|insured_1' : ''}|${unuseCouponTips}${tspId ? `|show_icon_${tspId}` : ''}\`mall_${mallCode}\``
  const traceid = traceId

  daEventCenter.triggerNotice({
    daId: '1-7-10-8',
    extraData: {
      goods_list,
      traceid
    }
  })
}

export const clickQtyInputReport = ({ item }) => {
  daEventCenter.triggerNotice({
    daId: '1-7-10-9',
    extraData: {
      skc: item.product?.goods_sn || '',
      sku: item.product?.sku_code || '',
    }
  })
}

export const deleteItemReport = ({ item }) => {
  let items_type = 'normalitem'
  if (item.aggregateProductBusiness?.isPresent == '1') {
    items_type = 'giftitem'
  } else if (item.aggregateProductBusiness?.isAddBuy == '1') {
    items_type = 'additem'
  }
  daEventCenter.triggerNotice({
    daId: '1-7-10-10',
    extraData: {
      available_point: '-',
      sence: '1',
      items_type,
      type_id: (item.product?.product_promotion_info?.map(item => item.type_id) || []).join(','),
    }
  })
}

export const pageViewExport = ({ type, state, promotion_code }) => {
  daEventCenter.triggerNotice({
    daId: '1-7-10-11',
    extraData: {
      type,
      state,
      promotion_code,
    }
  })
}

export const inputQtyChangeReport = ({ item, result }) => {
  daEventCenter.triggerNotice({
    daId: '1-7-10-12',
    extraData: {
      skc: item.product?.goods_sn || '',
      sku: item.product?.sku_code || '',
      result,
    }
  })
}

export const selectQtyChangeReport = ({ item, result }) => {
  daEventCenter.triggerNotice({
    daId: '1-7-10-13',
    extraData: {
      skc: item.product?.goods_sn || '',
      sku: item.product?.sku_code || '',
      result,
    }
  })
}

export const qtyExposeReport = ({ item }) => {
  daEventCenter.triggerNotice({
    daId: '1-7-10-14',
    extraData: {
      skc: item.product?.goods_sn || '',
      sku: item.product?.sku_code || '',
    }
  })
}
