<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import BusinessCartBff from 'public/src/pages/common/business-cart-bff/BusinessCart.vue'
import PreviewGoods from './PreviewGoods.vue'
import NumBadge from 'public/src/pages/common/add-on-dialog/components/NumBadge.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getLocalStorage } from '@shein/common-function'

onBeforeMount(() => {
  getAddressInfo()
  userLocalSizeCountry.value = getUserLocalSizeCountry()
})

const props = defineProps({
  type: { // 非标车类型
    type: String,
    default: 'coupon',
  },
  scene: { // 非标车场景
    type: String,
    default: 'couponAddItemPopup',
  },
  state: {
    type: String,
    default: '',
  },
  promotionId: {
    type: [Number, String],
    required: true,
  },
  language: {
    type: Object,
    default: () => ({})
  },
  status: { // 凑单状态: 0 - 全部未满足; 1 - 全部满足; 2 - 部分满足
    type: Number,
    default: null, //  0:全部未满足; 1:全部满足; 2:部分满足
  },
  autoUseCoupon: { // 自动用券
    type: String,
    default: '',
  },
  autoUsePoint: { // 自动用积分
    type: String,
    default: '',
  },
  theme: { // default 默认主题（优惠券非标和附属品活动非标在用） promotion 促销活动主题
    type: String,
    default: 'default',
  },
  themeVars: { // 顶替theme下的主题变量
    type: Object,
    default: () => ({})
  },
})
const emits = defineEmits(['cartUpdated', 'openBusinessCart', 'rePick'])

const visible = ref(false)
const popoverShow = ref(false)
const isFirst = ref(true)
const cartInfo = ref(null)
const timer = ref(null)
const cartItemId = ref(null)
const addressInfo = ref({})
const userLocalSizeCountry = ref('')

const cartRef = ref(null)

const supportItemInfo = computed(() => {
  return cartInfo.value?.supportItemInfo || {}
})
const unCheckedGoodsList = computed(() => {
  return cartInfo.value?.groups?.[0]?.content?.filter((v) => v.is_checked === '0') || []
})
const cartCheckedNum = computed(() => {
  return cartInfo.value?.metaInfo?.checkedCartSumQuantity || 0
})
const totalPrice = computed(() => {
  return cartInfo.value?.metaInfo?.totalPrice || {}
})
const savedPrice = computed(() => {
  return cartInfo.value?.metaInfo?.savedPrice || {}
})
const showDiscountPrice = computed(() => {
  return savedPrice.value?.price?.amount > 0
})
const uiConfig = computed(() => {
  return {
    showMorePromotion: '1', // 是否展示 more promotion 标签 '1'展示 '0' 不展示
    showFreeShip: '1', // 是否展示免邮标签 '1' 展示 '0' 不展示
    showQuickShip: '1', // 是否展示快速发货标签 '1' 展示 '0' 不展示
    showRepick: '1', // 是否展示重选按钮 '1' 当前凑单的附属品活动的已加车附属品的商品行上展示该按钮 '0' 不展示
    autoUseCoupon: props.autoUseCoupon, // 是否自动用券 '1' 中间层还需判断自动用券 abt 是否为开，开则自动用券，关则不自动用券，以防止非标车价格和下单页价格不一致 '0' 不自动使用
    autoUsePoint: props.autoUsePoint, // 是否自动用积分 '1' 中间层还需判断自动用积分 abt 是否为开，开则自动用积分，关则不自动用积分，以防止非标车价格和下单页价格不一致 '0' 不自动使用
  }
})

// 打开非标购物车
const openBusinessCart = (cartItem) => {
  userLocalSizeCountry.value = getUserLocalSizeCountry()
  emits('openBusinessCart')
  cartItemId.value = cartItem?.id
  closePopover()
  visible.value = true
  businessCartReport(cartItem?.id ? '1-7-4-20' : '1-7-4-18')
}
const closeBusinessCart = () => {
  visible.value = false
}

// 打开非标埋点
const businessCartReport = (daId) => {
  daEventCenter.triggerNotice({
    daId: daId,
    extraData: {
      promotion_code: props.promotionId,
      state: props.state,
    },
  })
}

const onCartUpdated = info => {
  cartInfo.value = info || {} // 更新底部车数据
  if (!visible.value) { // 打开了非标车不弹气泡
    handlePopover() // 弹出凑单商品气泡
  }
  emits('cartUpdated', info)
}

const handlePopover = () => {
  // 首次获取非标车，判断是否弹出povover
  if (!isFirst.value) return
  isFirst.value = false
  // 未满足最高档且有未勾选商品
  if (props.status !== 1 && supportItemInfo.value?.num > 0) {
    popoverShow.value = true
    timer.value = setTimeout(() => {
      closePopover()
    }, 5000)
  }
}
const closePopover = () => {
  popoverShow.value = false
  clearTimeout(timer.value)
}

const refetchCarts = () => { // 刷新非标购物车
  closePopover() // 关闭气泡
  cartRef.value?.refetchData?.()
}

const getAddressInfo = () => {
  let addressCookie = getLocalStorage('addressCookie') || {}
  let localCache = JSON.parse(localStorage.getItem('localCache')) || {}
  addressInfo.value = {
    district: localCache?.district || '',
    state: localCache?.state || '',
    city: localCache?.city || '',
    postcode: localCache?.postcode || '',
    addressId: addressCookie?.addressId || '',
    countryId: addressCookie?.countryId || '',
  }
}

const getUserLocalSizeCountry = () => {
  return typeof window === 'undefined' ? '' : (window.localStorage.getItem('last_select_country') || '')
}

const onRePick = () => {
  emits('rePick')
}

defineExpose({
  refetchCarts,
  closeBusinessCart,
})
</script>

<template>
  <div class="business-cart-foot">
    <div 
      v-expose="{id: '1-7-4-19', data: { promotion_code: promotionId, state: state }}"
      class="foot-bar"
      @click="openBusinessCart"
    >
      <s-popover
        v-model="popoverShow"
        placemen="top-start"
        theme="dark"
        show-close-icon
        :append-to-body="false"
        trigger="unset"
        :prop-style="{
          'margin-bottom': '12px',
          'padding': '10px',
          'max-width': '554px',
        }"
      >
        <div 
          class="unchecked-items" 
          @click.stop="openBusinessCart(unCheckedGoodsList[0])"
        >
          <PreviewGoods
            v-expose="{id: '1-7-4-21', data: { promotion_code: promotionId, state: state }}"
            :support-item-info="supportItemInfo"
          />
          <div 
            class="unchecked-tips" 
            v-html="language.SHEIN_KEY_PC_30155"
          >
          </div>
          <div class="pick-btn">
            {{ language.SHEIN_KEY_PC_30858 }}
          </div>
        </div>
        <template #reference>
          <NumBadge
            :value="cartCheckedNum"
            :max="99"
          >
            <img
              src="https://img.ltwebstatic.com/images3_acp/2024/03/15/a4/1710507571e64bf0c57e6ee9e2f3321242b1cb00f3.png"
              width="40"
              height="40"
            />
          </NumBadge>
        </template>
      </s-popover>
      <div class="price">
        <p 
          v-if="totalPrice.price"
          class="total"
          :class="{
            'color': totalPrice.color,
          }"
        >
          {{ totalPrice.price.amountWithSymbol }}
        </p>
        <p 
          v-if="showDiscountPrice"
          class="saved"
          :style="{
            'color': savedPrice.color,
          }"
        >
          {{ language.SHEIN_KEY_PC_29541 }} <b>{{ savedPrice.price.amountWithSymbol }}</b>
        </p>
      </div>
      <div class="divider"></div>
    </div>
    <slot></slot>

    <BusinessCartBff
      ref="cartRef"
      v-model:visible="visible"
      class="addon-business-cart"
      :type="type"
      :scene="scene"
      :state="state"
      :cart-item-id="cartItemId"
      :promotion-id="promotionId"
      :ui-config="uiConfig"
      :address="addressInfo"
      :user-local-size-country="userLocalSizeCountry"
      :theme="theme"
      :theme-vars="themeVars"
      @cart-updated="onCartUpdated"
      @re-pick="onRePick"
    />
  </div>
</template>

<style lang="less" scoped>
.business-cart-foot {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 9;
  text-align: left;
  .addon-business-cart {
    bottom: 55px;
  }
  
  :deep(.sui-popover__content-arrow) {
    bottom: -9px !important; /* stylelint-disable-line declaration-no-important */
    left: 30px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.foot-bar {
  display: flex;
  align-items: center;
  .price {
    width: 120px;
    margin-left: 12px;
    .total {
      font-size: 15px;
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .saved {
      font-size: 10px;
      line-height: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .divider {
    width: 1px;
    height: 24px;
    background-color: #E5E5E5;
    margin: 0 20px;
  }
}
.unchecked-items {
  display: flex;
  align-items: center;
  overflow: hidden;
  .unchecked-tips {
    width: 250px;
    margin: 0 16px 0 8px;
    font-size: 14px;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .pick-btn {
    cursor: pointer;
    width: 80px;
    font-size: 12px;
    font-weight: bold;
    padding: 7px 10px;
    color: #fff;
    background-color: @sui_color_discount;
    text-align: center;
  }
}
</style>
