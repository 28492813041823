/**
 * 判断是否是定制商品
 * @param {Object} item 商品信息
 */
export function isCustomizationProduct(item = {}) {
  const { aggregateProductBusiness } = item
  return (
    aggregateProductBusiness?.customizationPopInfo?.customContent &&
    aggregateProductBusiness?.customizationPopInfo?.effects &&
    aggregateProductBusiness?.customizationPopInfo?.images &&
    aggregateProductBusiness?.customizationPopInfo?.texts
  )
}
