<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { sui_icon_more_down_14px } from '@shein-aidc/icon-vue3'
import { throttle } from '@shein/common-function'

const props = defineProps({
  target: {
    type: String,
    default: '.j-bc-content__groups'
  },
  visibilityTarget: {
    type: String,
    default: ''
  },
  right: {
    type: String,
    default: '16px'
  },
  bottom: {
    type: String,
    default: '70px'
  },
  zIndex: {
    type: Number,
    default: 100
  }
})

const show = ref(false)

const styles = computed(() => {
  return {
    '--right': props.right,
    '--bottom': props.bottom,
    '--z-index': props.zIndex
  }
})

const handleClick = () => {
  const scrollEl = document.querySelector(props.target)
  if (scrollEl && scrollEl.scrollTo) {
    scrollEl.scrollTo({ top: 0, behavior: 'smooth' })
  } else {
    scrollEl.scrollTop = 0
  }
}

const scroll = throttle({
  func: () => {
    const targetEl = document.querySelector(props.target)
    const visibilityTargetEl = document.querySelector(props.visibilityTarget)
    if (!visibilityTargetEl) return
    const rect = visibilityTargetEl.getBoundingClientRect()
    const el = targetEl.getBoundingClientRect()
    show.value = rect.top - el.top <= 0
  },
  wait: 100,
})

onMounted(() => {
  const scrollEl = document.querySelector(props.target)
  if (scrollEl) {
    scroll()
    scrollEl.addEventListener('scroll', scroll)
  }
})
onUnmounted(() => {
  show.value = false
  const scrollEl = document.querySelector(props.target)
  if (scrollEl) {
    scrollEl.removeEventListener('scroll', scroll)
  }
})
</script>

<template>
  <Transition name="scale">
    <div
      v-show="show"
      class="bc-back-top"
      :style="styles"
      @click="handleClick"
    >
      <sui_icon_more_down_14px class="bc-back-top__icon" />
    </div>
  </Transition>
</template>

<style lang="less" scoped>
.bc-back-top {
  position: absolute;
  right: var(--right);
  bottom: var(--bottom);
  z-index: var(--z-index);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background-color: #fff;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  cursor: pointer;
  .bc-back-top__icon {
    transform: rotate(-180deg);
  };
}

.scale-enter-active,
.scale-leave-active {
  transition: transform 0.1s linear;
}

.scale-enter-from,
.scale-leave-to {
  transform: scale(0);
}
</style>
