<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang='ts'>

</script>

<template>
  <div class="skeleton">
    <header class="skeleton__header">
      <span class="skeleton__title skeleton__item"></span>
    </header>
    <span class="skeleton__cloase skeleton__item"></span>
    <div class="skeleton__content">
      <div
        v-for="i in 2"
        :key="i"
        class="skeleton__group"
      >
        <div class="skeleton__group-header">
          <span class="skeleton__group-title skeleton__item"></span>
          <span class="skeleton__group-price skeleton__item"></span>
        </div>
        <div
          v-for="index in 3"
          :key="index"
          class="skeleton__group-item"
        >
          <div class="checkbox skeleton__item"></div>
          <div class="img skeleton__item"></div>
          <div class="info">
            <div class="title skeleton__item"></div>
            <div class="attr skeleton__item"></div>
            <div class="tag skeleton__item"></div>
            <div class="bottom">
              <div class="price skeleton__item"></div>
              <div class="operation skeleton__item"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.skeleton {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 12px 12px 0 0;
  background: #fff;
  .skeleton__header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
  }
  .skeleton__title {
    width: 200px;
    height: 26px;
  }
  .skeleton__cloase {
    position: absolute;
    top: 12px;
    right: 11px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .skeleton__content {
    flex: 1;
    overflow: hidden;
    padding: 0 16px 20px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.30);
      border-radius: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
  .skeleton__group {
    width: 100%;
    border-radius: 10px;
    background: #f9f9f9;
    padding-bottom: 10px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .skeleton__group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    height: 48px;
  }
  .skeleton__group-title {
    width: 400px;
    height: 20px;
  }
  .skeleton__group-price {
    width: 120px;
    height: 20px;
  }
  .skeleton__group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    width: 100%;
    .checkbox {
      width: 20px;
      height: 20px;
    }
    .img {
      margin: 0 16px;
      width: 120px;
      height: 120px;
    }
    .info {
      flex: 1;
      height: 120px;
    }
    .title {
      width: 400px;
      height: 16px;
    }
    .attr {
      margin: 8px 0;
      width: 80px;
      height: 16px;
    }
    .tag {
      width: 160px;
      height: 20px;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 22px;
    }
    .price {
      width: 100px;
      height: 24px;
    }
    .operation {
      width: 87px;
      height: 24px;
    }
  }



  .skeleton__item {
    background-color: #f2f2f2;
  }
}

</style>
