<template>
  <div class="num-badge">
    <!-- main -->
    <span 
      v-if="showValue"
      class="badge-bubble"
      :style="badgeStyle"
    >
      {{ showValue }}
    </span>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'NumBadge',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    max: {
      type: Number,
      default: 0
    },
    badgeStyle: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    showValue() {
      if (typeof this.value == 'number' && this.max > 0) {
        return this.value > this.max ? `${this.max}+` : this.value
      }

      return String(this.value)
    }
  }
}
</script>

<style lang="less" scoped>
.num-badge {
  display: inline-block;
  position: relative;

  .badge-bubble {
    position: absolute;
    bottom: 65%;
    right: 15%;
    transform: translateX(50%);
    box-sizing: content-box;
    padding: 1px 3px;
    height: 13px;
    min-width: 9px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    z-index: 1;
    transform: translateY(50%);
    background: #F00;
    border: 1px solid #FFF;
    color: #fff;
  }
}
</style>
